@import "./src/App";

.customButton {
  border-radius: 0;
}

//$theme-colors: (
//        "primary": $blue-color,
//);

//$btn-background: #39a4d2;
//$btn-border: #56a1c5;
//$btn-color: #fff;
//
//@mixin button-variant($background, $border, $color) {
//  background-color: $btn-background;
//  border: 2px solid $btn-border;
//  color: #fff;
//
//  &:hover {
//    border: 2px solid $btn-background;
//    color: $btn-color;
//  }
//}
//
//.btn-primary {
//  &.basic {
//    @include button-variant($btn-background, $btn-border, $btn-color);
//  }
//}