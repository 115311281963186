@import "src/App.scss";

.headerContainer {
  padding-left: 2em;
  padding-right: 2em;
}

.kiwanisIconLogo {
  padding-right: 0.3em;
}

.kiwanisIconLogo img {
  height: 35px;
}

.kiwanisLogo img {
  height: 24px;
}

.topMenu a {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.2px;
}



